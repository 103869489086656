<template>
  <div>
    <el-card>
      <Combination :listName="title"></Combination>
      <div class="add-president-cnt">
        <el-form
          :model="groupForm"
          status-icon
          :rules="rules"
          ref="groupRuleForm"
          label-width="150px"
          class="employee-formRule"
        >
          <el-form-item label="组长ID:" prop="groupMap">
            <el-select
              v-model="groupForm.groupMap"
              filterable
              placeholder="请选择"
              clearable
              class="add-select"
            >
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="组名称:" prop="groupName">
            <el-input v-model="groupForm.groupName"></el-input>
          </el-form-item>

          <!-- <el-form-item label="是否激活:" prop="isActived">
            <el-radio v-model="groupForm.isActived" label="1">是</el-radio>
            <el-radio v-model="groupForm.isActived" label="2">否</el-radio>
          </el-form-item> -->
          <el-form-item label="备注:">
            <el-input
              v-model="groupForm.groupRemark"
              type="textarea"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="groupSubmitForm('groupRuleForm')"
              >添加</el-button
            >
            <el-button @click="groupResetForm('groupRuleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>
<script>
import Combination from '@/components/Combination.vue';
import {
  checkgroupName,
  checkisActived,
  checkgroupMap,
} from '@/util/proofread';
import { getEmployeeList, addGroup } from '@/service/moudules/platformApi';
import { getCookie } from '@/util/userLoginFn';
export default {
  components: {
    Combination,
  },
  data() {
    return {
      getCookie,
      rules: {
        groupMap: [
          { validator: checkgroupName, trigger: 'change', required: true },
        ],
        groupName: [
          { validator: checkgroupMap, trigger: 'blur', required: true },
        ],
        isActived: [
          { validator: checkisActived, trigger: 'blur', required: true },
        ],
      },
      groupForm: {
        isActived: '1',
        groupName: '',
        groupRemark: '',
        groupMap: '',
      },
      options: [],
      title: '添加小组信息',
    };
  },
  methods: {
    groupSubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addfomations();
        } else {
          return false;
        }
      });
    },
    groupResetForm(formName) {
      this.$refs[formName].resetFields();
    },
    async addfomations() {
      try {
        let {
          isActived,
          groupName,
          stuffName,
          groupMap,
          groupRemark,
        } = this.groupForm;

        let params = {
          isActivied: Number(isActived),
          groupName: groupName,
          teamLeadId: groupMap,
          remark: groupRemark,
          sessionId: this.getCookie('sessionId'),
        };
        let { data, msg, code } = await addGroup(params);

        if (code !== 200 && msg !== 'success') {
          this.$message({
            type: 'error',
            message: msg,
          });
          return false;
        }

        this.$message({
          type: 'success',
          message: '添加信息成功',
        });
        this.$router.push({
          path: '/group',
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getEmployeeLists() {
      try {
        let params = `?pageNo=1&pageSize=200&type=2`;

        const { code, msg, data } = await getEmployeeList(params);
        if (code !== 200 && data.records.length === 0) {
          this.$message({
            type: 'error',
            message: msg,
          });
          return false;
        }
        this.options = data.records.map((item, index) => {
          return {
            value: item.stuffId,
            label: item.stuffName,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getEmployeeLists();
  },
};
</script>
<style lang="less">
.employee-formRule {
  width: 500px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .add-select {
    width: 350px;
  }
}
.add-president-cnt {
  display: flex;
  justify-content: center;
}
</style>
